/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../utils/api";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const promoteReleaseBundle = /* GraphQL */ `mutation PromoteReleaseBundle($releaseBundle: String!, $env: String) {
  promoteReleaseBundle(releaseBundle: $releaseBundle, env: $env)
}
` as GeneratedMutation<
  APITypes.PromoteReleaseBundleMutationVariables,
  APITypes.PromoteReleaseBundleMutation
>;
export const sendBasicSolution = /* GraphQL */ `mutation SendBasicSolution($solution: ID!, $env: String) {
  sendBasicSolution(solution: $solution, env: $env)
}
` as GeneratedMutation<
  APITypes.SendBasicSolutionMutationVariables,
  APITypes.SendBasicSolutionMutation
>;
export const sendHistory = /* GraphQL */ `mutation SendHistory($history: ID!, $env: String) {
  sendHistory(history: $history, env: $env)
}
` as GeneratedMutation<
  APITypes.SendHistoryMutationVariables,
  APITypes.SendHistoryMutation
>;
export const sendAdvancedSolution = /* GraphQL */ `mutation SendAdvancedSolution(
  $solution: ID!
  $env: String
  $pathToSave: [String!]!
  $history: ID!
) {
  sendAdvancedSolution(
    solution: $solution
    env: $env
    pathToSave: $pathToSave
    history: $history
  )
}
` as GeneratedMutation<
  APITypes.SendAdvancedSolutionMutationVariables,
  APITypes.SendAdvancedSolutionMutation
>;
export const generateEmbeddings = /* GraphQL */ `mutation GenerateEmbeddings($customComponentID: ID!, $version: String!) {
  generateEmbeddings(customComponentID: $customComponentID, version: $version)
}
` as GeneratedMutation<
  APITypes.GenerateEmbeddingsMutationVariables,
  APITypes.GenerateEmbeddingsMutation
>;
export const upsertDocument = /* GraphQL */ `mutation UpsertDocument($documentID: ID!, $documentSetSlug: String!) {
  upsertDocument(documentID: $documentID, documentSetSlug: $documentSetSlug)
}
` as GeneratedMutation<
  APITypes.UpsertDocumentMutationVariables,
  APITypes.UpsertDocumentMutation
>;
export const removeDocument = /* GraphQL */ `mutation RemoveDocument($documentID: ID!, $documentSetSlug: String!) {
  removeDocument(documentID: $documentID, documentSetSlug: $documentSetSlug)
}
` as GeneratedMutation<
  APITypes.RemoveDocumentMutationVariables,
  APITypes.RemoveDocumentMutation
>;
export const removeNamespace = /* GraphQL */ `mutation RemoveNamespace($namespaceID: ID!, $documentSetSlug: String!) {
  removeNamespace(namespaceID: $namespaceID, documentSetSlug: $documentSetSlug)
}
` as GeneratedMutation<
  APITypes.RemoveNamespaceMutationVariables,
  APITypes.RemoveNamespaceMutation
>;
export const generateAnswers = /* GraphQL */ `mutation GenerateAnswers(
  $keys: [String!]!
  $customComponentID: String!
  $namespaceID: String!
  $documentSetSlug: String!
  $promptSetType: PromptSetTypes!
  $prompt: String!
  $chat: [ChatPromptInput!]!
) {
  generateAnswers(
    keys: $keys
    customComponentID: $customComponentID
    namespaceID: $namespaceID
    documentSetSlug: $documentSetSlug
    promptSetType: $promptSetType
    prompt: $prompt
    chat: $chat
  )
}
` as GeneratedMutation<
  APITypes.GenerateAnswersMutationVariables,
  APITypes.GenerateAnswersMutation
>;
export const createVailEngagementGoal = /* GraphQL */ `mutation CreateVailEngagementGoal(
  $input: CreateVailEngagementGoalInput!
  $condition: ModelVailEngagementGoalConditionInput
) {
  createVailEngagementGoal(input: $input, condition: $condition) {
    admins
    editors
    readers
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    name
    description
    priority
    script {
      name
      version
      type
      extra
      __typename
    }
    creation {
      createInRecipe
      __typename
    }
    archived
    spec
    cards {
      nextToken
      __typename
    }
    notification
    initialConversation
    id
    createdAt
    updatedAt
    organizationEngagementGoalsId
    vailEngagementGoalUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVailEngagementGoalMutationVariables,
  APITypes.CreateVailEngagementGoalMutation
>;
export const updateVailEngagementGoal = /* GraphQL */ `mutation UpdateVailEngagementGoal(
  $input: UpdateVailEngagementGoalInput!
  $condition: ModelVailEngagementGoalConditionInput
) {
  updateVailEngagementGoal(input: $input, condition: $condition) {
    admins
    editors
    readers
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    name
    description
    priority
    script {
      name
      version
      type
      extra
      __typename
    }
    creation {
      createInRecipe
      __typename
    }
    archived
    spec
    cards {
      nextToken
      __typename
    }
    notification
    initialConversation
    id
    createdAt
    updatedAt
    organizationEngagementGoalsId
    vailEngagementGoalUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVailEngagementGoalMutationVariables,
  APITypes.UpdateVailEngagementGoalMutation
>;
export const deleteVailEngagementGoal = /* GraphQL */ `mutation DeleteVailEngagementGoal(
  $input: DeleteVailEngagementGoalInput!
  $condition: ModelVailEngagementGoalConditionInput
) {
  deleteVailEngagementGoal(input: $input, condition: $condition) {
    admins
    editors
    readers
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    name
    description
    priority
    script {
      name
      version
      type
      extra
      __typename
    }
    creation {
      createInRecipe
      __typename
    }
    archived
    spec
    cards {
      nextToken
      __typename
    }
    notification
    initialConversation
    id
    createdAt
    updatedAt
    organizationEngagementGoalsId
    vailEngagementGoalUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVailEngagementGoalMutationVariables,
  APITypes.DeleteVailEngagementGoalMutation
>;
export const createVailFAQ = /* GraphQL */ `mutation CreateVailFAQ(
  $input: CreateVailFAQInput!
  $condition: ModelVailFAQConditionInput
) {
  createVailFAQ(input: $input, condition: $condition) {
    editors
    readers
    admins
    autoSuggestConfig {
      ignoreWords
      minimumCharacters
      preSearchPrompt
      questionNotFoundButton
      returnToMainMenuButton
      searchBoxPlaceholder
      __typename
    }
    menuConfig {
      answerCompleteButton
      defaultQuestions
      disclaimerText
      displayRandomQuestionsInMenu
      exitFaqComponentButton
      startSearchButton
      questionNotFoundStep
      faqCompleteNextStep
      menuPrompt
      persistentQuestion
      __typename
    }
    name
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    questions {
      disclaimer
      key
      id
      keywords
      question
      suggestedQuestions
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    active
    id
    createdAt
    updatedAt
    organizationFAQId
    vailFAQUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVailFAQMutationVariables,
  APITypes.CreateVailFAQMutation
>;
export const updateVailFAQ = /* GraphQL */ `mutation UpdateVailFAQ(
  $input: UpdateVailFAQInput!
  $condition: ModelVailFAQConditionInput
) {
  updateVailFAQ(input: $input, condition: $condition) {
    editors
    readers
    admins
    autoSuggestConfig {
      ignoreWords
      minimumCharacters
      preSearchPrompt
      questionNotFoundButton
      returnToMainMenuButton
      searchBoxPlaceholder
      __typename
    }
    menuConfig {
      answerCompleteButton
      defaultQuestions
      disclaimerText
      displayRandomQuestionsInMenu
      exitFaqComponentButton
      startSearchButton
      questionNotFoundStep
      faqCompleteNextStep
      menuPrompt
      persistentQuestion
      __typename
    }
    name
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    questions {
      disclaimer
      key
      id
      keywords
      question
      suggestedQuestions
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    active
    id
    createdAt
    updatedAt
    organizationFAQId
    vailFAQUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVailFAQMutationVariables,
  APITypes.UpdateVailFAQMutation
>;
export const deleteVailFAQ = /* GraphQL */ `mutation DeleteVailFAQ(
  $input: DeleteVailFAQInput!
  $condition: ModelVailFAQConditionInput
) {
  deleteVailFAQ(input: $input, condition: $condition) {
    editors
    readers
    admins
    autoSuggestConfig {
      ignoreWords
      minimumCharacters
      preSearchPrompt
      questionNotFoundButton
      returnToMainMenuButton
      searchBoxPlaceholder
      __typename
    }
    menuConfig {
      answerCompleteButton
      defaultQuestions
      disclaimerText
      displayRandomQuestionsInMenu
      exitFaqComponentButton
      startSearchButton
      questionNotFoundStep
      faqCompleteNextStep
      menuPrompt
      persistentQuestion
      __typename
    }
    name
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    questions {
      disclaimer
      key
      id
      keywords
      question
      suggestedQuestions
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    active
    id
    createdAt
    updatedAt
    organizationFAQId
    vailFAQUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVailFAQMutationVariables,
  APITypes.DeleteVailFAQMutation
>;
export const deleteVailHistory = /* GraphQL */ `mutation DeleteVailHistory(
  $input: DeleteVailHistoryInput!
  $condition: ModelVailHistoryConditionInput
) {
  deleteVailHistory(input: $input, condition: $condition) {
    editors
    readers
    admins
    createdBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    data
    name
    notes
    platformKeys {
      lowerEnv
      middleEnv
      upperEnv
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    id
    createdAt
    updatedAt
    vailEngagementGoalHistoryId
    vailFAQHistoryId
    organizationHistoryId
    vailSolutionHistoryId
    recipeHistoryId
    vailReleaseHistoryId
    customComponentHistoryId
    vailHistoryCreatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVailHistoryMutationVariables,
  APITypes.DeleteVailHistoryMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    editors
    readers
    admins
    solution {
      nextToken
      __typename
    }
    FAQ {
      nextToken
      __typename
    }
    engagementGoals {
      nextToken
      __typename
    }
    history {
      nextToken
      __typename
    }
    name
    orgStatus
    privacyPolicy
    recipe {
      nextToken
      __typename
    }
    egCards {
      nextToken
      __typename
    }
    releases {
      nextToken
      __typename
    }
    slug
    tags
    termsOfUse
    phoneNumber
    customComponents {
      nextToken
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    promptSets {
      nextToken
      __typename
    }
    namespaces {
      nextToken
      __typename
    }
    documents {
      nextToken
      __typename
    }
    solutionDeployed
    id
    createdAt
    updatedAt
    organizationUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const deleteEgCard = /* GraphQL */ `mutation DeleteEgCard(
  $input: DeleteEgCardInput!
  $condition: ModelEgCardConditionInput
) {
  deleteEgCard(input: $input, condition: $condition) {
    editors
    readers
    admins
    engagementGoal {
      admins
      editors
      readers
      name
      description
      priority
      archived
      spec
      notification
      initialConversation
      id
      createdAt
      updatedAt
      organizationEngagementGoalsId
      vailEngagementGoalUpdatedById
      __typename
    }
    version {
      editors
      readers
      admins
      data
      name
      notes
      id
      createdAt
      updatedAt
      vailEngagementGoalHistoryId
      vailFAQHistoryId
      organizationHistoryId
      vailSolutionHistoryId
      recipeHistoryId
      vailReleaseHistoryId
      customComponentHistoryId
      vailHistoryCreatedById
      __typename
    }
    solution {
      editors
      readers
      name
      type
      description
      entryPoint
      usesYML
      script
      lastDeployed
      visibility
      id
      createdAt
      updatedAt
      organizationSolutionId
      vailSolutionUpdatedById
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    id
    createdAt
    updatedAt
    vailEngagementGoalCardsId
    organizationEgCardsId
    vailSolutionEngagementGoalsId
    egCardVersionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEgCardMutationVariables,
  APITypes.DeleteEgCardMutation
>;
export const deleteVailSolution = /* GraphQL */ `mutation DeleteVailSolution(
  $input: DeleteVailSolutionInput!
  $condition: ModelVailSolutionConditionInput
) {
  deleteVailSolution(input: $input, condition: $condition) {
    editors
    readers
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    name
    type
    description
    entryPoint
    components {
      name
      version
      type
      extra
      __typename
    }
    usesYML
    script
    engagementGoals {
      nextToken
      __typename
    }
    lastDeployed
    visibility
    id
    createdAt
    updatedAt
    organizationSolutionId
    vailSolutionUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVailSolutionMutationVariables,
  APITypes.DeleteVailSolutionMutation
>;
export const deleteRecipe = /* GraphQL */ `mutation DeleteRecipe(
  $input: DeleteRecipeInput!
  $condition: ModelRecipeConditionInput
) {
  deleteRecipe(input: $input, condition: $condition) {
    editors
    readers
    admins
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    scriptRecipe
    settings {
      description
      name
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    scriptCode
    id
    createdAt
    updatedAt
    organizationRecipeId
    recipeUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRecipeMutationVariables,
  APITypes.DeleteRecipeMutation
>;
export const createCognitoUser = /* GraphQL */ `mutation CreateCognitoUser(
  $input: CreateCognitoUserInput!
  $condition: ModelCognitoUserConditionInput
) {
  createCognitoUser(input: $input, condition: $condition) {
    owner
    email
    name
    role
    settings {
      fontSize
      theme
      recipeDelay
      __typename
    }
    demosShown
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCognitoUserMutationVariables,
  APITypes.CreateCognitoUserMutation
>;
export const updateCognitoUser = /* GraphQL */ `mutation UpdateCognitoUser(
  $input: UpdateCognitoUserInput!
  $condition: ModelCognitoUserConditionInput
) {
  updateCognitoUser(input: $input, condition: $condition) {
    owner
    email
    name
    role
    settings {
      fontSize
      theme
      recipeDelay
      __typename
    }
    demosShown
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCognitoUserMutationVariables,
  APITypes.UpdateCognitoUserMutation
>;
export const deleteCognitoUser = /* GraphQL */ `mutation DeleteCognitoUser(
  $input: DeleteCognitoUserInput!
  $condition: ModelCognitoUserConditionInput
) {
  deleteCognitoUser(input: $input, condition: $condition) {
    owner
    email
    name
    role
    settings {
      fontSize
      theme
      recipeDelay
      __typename
    }
    demosShown
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCognitoUserMutationVariables,
  APITypes.DeleteCognitoUserMutation
>;
export const deleteVailRelease = /* GraphQL */ `mutation DeleteVailRelease(
  $input: DeleteVailReleaseInput!
  $condition: ModelVailReleaseConditionInput
) {
  deleteVailRelease(input: $input, condition: $condition) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    version
    name
    createdBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    releaseNotes
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    bundle {
      name
      version
      type
      extra
      __typename
    }
    history {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    organizationReleasesId
    vailReleaseCreatedById
    vailReleaseUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVailReleaseMutationVariables,
  APITypes.DeleteVailReleaseMutation
>;
export const deletePromptSet = /* GraphQL */ `mutation DeletePromptSet(
  $input: DeletePromptSetInput!
  $condition: ModelPromptSetConditionInput
) {
  deletePromptSet(input: $input, condition: $condition) {
    editors
    readers
    admins
    chat {
      role
      response
      __typename
    }
    maxTokens
    name
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    prompt
    temperature
    type
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    id
    createdAt
    updatedAt
    organizationPromptSetsId
    promptSetUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePromptSetMutationVariables,
  APITypes.DeletePromptSetMutation
>;
export const createDictionary = /* GraphQL */ `mutation CreateDictionary(
  $input: CreateDictionaryInput!
  $condition: ModelDictionaryConditionInput
) {
  createDictionary(input: $input, condition: $condition) {
    editors
    readers
    admins
    medicationName
    typeOfMedication
    customerProfile
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDictionaryMutationVariables,
  APITypes.CreateDictionaryMutation
>;
export const updateDictionary = /* GraphQL */ `mutation UpdateDictionary(
  $input: UpdateDictionaryInput!
  $condition: ModelDictionaryConditionInput
) {
  updateDictionary(input: $input, condition: $condition) {
    editors
    readers
    admins
    medicationName
    typeOfMedication
    customerProfile
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDictionaryMutationVariables,
  APITypes.UpdateDictionaryMutation
>;
export const deleteDictionary = /* GraphQL */ `mutation DeleteDictionary(
  $input: DeleteDictionaryInput!
  $condition: ModelDictionaryConditionInput
) {
  deleteDictionary(input: $input, condition: $condition) {
    editors
    readers
    admins
    medicationName
    typeOfMedication
    customerProfile
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDictionaryMutationVariables,
  APITypes.DeleteDictionaryMutation
>;
export const createJob = /* GraphQL */ `mutation CreateJob(
  $input: CreateJobInput!
  $condition: ModelJobConditionInput
) {
  createJob(input: $input, condition: $condition) {
    user {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    status
    name
    displayStatus
    id
    createdAt
    updatedAt
    jobUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobMutationVariables,
  APITypes.CreateJobMutation
>;
export const updateJob = /* GraphQL */ `mutation UpdateJob(
  $input: UpdateJobInput!
  $condition: ModelJobConditionInput
) {
  updateJob(input: $input, condition: $condition) {
    user {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    status
    name
    displayStatus
    id
    createdAt
    updatedAt
    jobUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobMutationVariables,
  APITypes.UpdateJobMutation
>;
export const deleteJob = /* GraphQL */ `mutation DeleteJob(
  $input: DeleteJobInput!
  $condition: ModelJobConditionInput
) {
  deleteJob(input: $input, condition: $condition) {
    user {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    status
    name
    displayStatus
    id
    createdAt
    updatedAt
    jobUserId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobMutationVariables,
  APITypes.DeleteJobMutation
>;
export const deleteNamespace = /* GraphQL */ `mutation DeleteNamespace(
  $input: DeleteNamespaceInput!
  $condition: ModelNamespaceConditionInput
) {
  deleteNamespace(input: $input, condition: $condition) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    name
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    documents {
      nextToken
      __typename
    }
    indexName
    status
    displayStatus
    id
    createdAt
    updatedAt
    organizationNamespacesId
    namespaceUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNamespaceMutationVariables,
  APITypes.DeleteNamespaceMutation
>;
export const createCustomComponent = /* GraphQL */ `mutation CreateCustomComponent(
  $input: CreateCustomComponentInput!
  $condition: ModelCustomComponentConditionInput
) {
  createCustomComponent(input: $input, condition: $condition) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    name
    description
    type
    settings
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    history {
      nextToken
      __typename
    }
    status
    displayStatus
    visibility
    id
    createdAt
    updatedAt
    organizationCustomComponentsId
    customComponentUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomComponentMutationVariables,
  APITypes.CreateCustomComponentMutation
>;
export const deleteCustomComponent = /* GraphQL */ `mutation DeleteCustomComponent(
  $input: DeleteCustomComponentInput!
  $condition: ModelCustomComponentConditionInput
) {
  deleteCustomComponent(input: $input, condition: $condition) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    name
    description
    type
    settings
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    history {
      nextToken
      __typename
    }
    status
    displayStatus
    visibility
    id
    createdAt
    updatedAt
    organizationCustomComponentsId
    customComponentUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomComponentMutationVariables,
  APITypes.DeleteCustomComponentMutation
>;
export const createVailHistory = /* GraphQL */ `mutation CreateVailHistory(
  $input: CreateVailHistoryInput!
  $condition: ModelVailHistoryConditionInput
) {
  createVailHistory(input: $input, condition: $condition) {
    editors
    readers
    admins
    createdBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    data
    name
    notes
    platformKeys {
      lowerEnv
      middleEnv
      upperEnv
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    id
    createdAt
    updatedAt
    vailEngagementGoalHistoryId
    vailFAQHistoryId
    organizationHistoryId
    vailSolutionHistoryId
    recipeHistoryId
    vailReleaseHistoryId
    customComponentHistoryId
    vailHistoryCreatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVailHistoryMutationVariables,
  APITypes.CreateVailHistoryMutation
>;
export const updateVailHistory = /* GraphQL */ `mutation UpdateVailHistory(
  $input: UpdateVailHistoryInput!
  $condition: ModelVailHistoryConditionInput
) {
  updateVailHistory(input: $input, condition: $condition) {
    editors
    readers
    admins
    createdBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    data
    name
    notes
    platformKeys {
      lowerEnv
      middleEnv
      upperEnv
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    id
    createdAt
    updatedAt
    vailEngagementGoalHistoryId
    vailFAQHistoryId
    organizationHistoryId
    vailSolutionHistoryId
    recipeHistoryId
    vailReleaseHistoryId
    customComponentHistoryId
    vailHistoryCreatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVailHistoryMutationVariables,
  APITypes.UpdateVailHistoryMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    editors
    readers
    admins
    solution {
      nextToken
      __typename
    }
    FAQ {
      nextToken
      __typename
    }
    engagementGoals {
      nextToken
      __typename
    }
    history {
      nextToken
      __typename
    }
    name
    orgStatus
    privacyPolicy
    recipe {
      nextToken
      __typename
    }
    egCards {
      nextToken
      __typename
    }
    releases {
      nextToken
      __typename
    }
    slug
    tags
    termsOfUse
    phoneNumber
    customComponents {
      nextToken
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    promptSets {
      nextToken
      __typename
    }
    namespaces {
      nextToken
      __typename
    }
    documents {
      nextToken
      __typename
    }
    solutionDeployed
    id
    createdAt
    updatedAt
    organizationUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    editors
    readers
    admins
    solution {
      nextToken
      __typename
    }
    FAQ {
      nextToken
      __typename
    }
    engagementGoals {
      nextToken
      __typename
    }
    history {
      nextToken
      __typename
    }
    name
    orgStatus
    privacyPolicy
    recipe {
      nextToken
      __typename
    }
    egCards {
      nextToken
      __typename
    }
    releases {
      nextToken
      __typename
    }
    slug
    tags
    termsOfUse
    phoneNumber
    customComponents {
      nextToken
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    promptSets {
      nextToken
      __typename
    }
    namespaces {
      nextToken
      __typename
    }
    documents {
      nextToken
      __typename
    }
    solutionDeployed
    id
    createdAt
    updatedAt
    organizationUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const createEgCard = /* GraphQL */ `mutation CreateEgCard(
  $input: CreateEgCardInput!
  $condition: ModelEgCardConditionInput
) {
  createEgCard(input: $input, condition: $condition) {
    editors
    readers
    admins
    engagementGoal {
      admins
      editors
      readers
      name
      description
      priority
      archived
      spec
      notification
      initialConversation
      id
      createdAt
      updatedAt
      organizationEngagementGoalsId
      vailEngagementGoalUpdatedById
      __typename
    }
    version {
      editors
      readers
      admins
      data
      name
      notes
      id
      createdAt
      updatedAt
      vailEngagementGoalHistoryId
      vailFAQHistoryId
      organizationHistoryId
      vailSolutionHistoryId
      recipeHistoryId
      vailReleaseHistoryId
      customComponentHistoryId
      vailHistoryCreatedById
      __typename
    }
    solution {
      editors
      readers
      name
      type
      description
      entryPoint
      usesYML
      script
      lastDeployed
      visibility
      id
      createdAt
      updatedAt
      organizationSolutionId
      vailSolutionUpdatedById
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    id
    createdAt
    updatedAt
    vailEngagementGoalCardsId
    organizationEgCardsId
    vailSolutionEngagementGoalsId
    egCardVersionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEgCardMutationVariables,
  APITypes.CreateEgCardMutation
>;
export const updateEgCard = /* GraphQL */ `mutation UpdateEgCard(
  $input: UpdateEgCardInput!
  $condition: ModelEgCardConditionInput
) {
  updateEgCard(input: $input, condition: $condition) {
    editors
    readers
    admins
    engagementGoal {
      admins
      editors
      readers
      name
      description
      priority
      archived
      spec
      notification
      initialConversation
      id
      createdAt
      updatedAt
      organizationEngagementGoalsId
      vailEngagementGoalUpdatedById
      __typename
    }
    version {
      editors
      readers
      admins
      data
      name
      notes
      id
      createdAt
      updatedAt
      vailEngagementGoalHistoryId
      vailFAQHistoryId
      organizationHistoryId
      vailSolutionHistoryId
      recipeHistoryId
      vailReleaseHistoryId
      customComponentHistoryId
      vailHistoryCreatedById
      __typename
    }
    solution {
      editors
      readers
      name
      type
      description
      entryPoint
      usesYML
      script
      lastDeployed
      visibility
      id
      createdAt
      updatedAt
      organizationSolutionId
      vailSolutionUpdatedById
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    id
    createdAt
    updatedAt
    vailEngagementGoalCardsId
    organizationEgCardsId
    vailSolutionEngagementGoalsId
    egCardVersionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEgCardMutationVariables,
  APITypes.UpdateEgCardMutation
>;
export const createVailSolution = /* GraphQL */ `mutation CreateVailSolution(
  $input: CreateVailSolutionInput!
  $condition: ModelVailSolutionConditionInput
) {
  createVailSolution(input: $input, condition: $condition) {
    editors
    readers
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    name
    type
    description
    entryPoint
    components {
      name
      version
      type
      extra
      __typename
    }
    usesYML
    script
    engagementGoals {
      nextToken
      __typename
    }
    lastDeployed
    visibility
    id
    createdAt
    updatedAt
    organizationSolutionId
    vailSolutionUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVailSolutionMutationVariables,
  APITypes.CreateVailSolutionMutation
>;
export const updateVailSolution = /* GraphQL */ `mutation UpdateVailSolution(
  $input: UpdateVailSolutionInput!
  $condition: ModelVailSolutionConditionInput
) {
  updateVailSolution(input: $input, condition: $condition) {
    editors
    readers
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    name
    type
    description
    entryPoint
    components {
      name
      version
      type
      extra
      __typename
    }
    usesYML
    script
    engagementGoals {
      nextToken
      __typename
    }
    lastDeployed
    visibility
    id
    createdAt
    updatedAt
    organizationSolutionId
    vailSolutionUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVailSolutionMutationVariables,
  APITypes.UpdateVailSolutionMutation
>;
export const createRecipe = /* GraphQL */ `mutation CreateRecipe(
  $input: CreateRecipeInput!
  $condition: ModelRecipeConditionInput
) {
  createRecipe(input: $input, condition: $condition) {
    editors
    readers
    admins
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    scriptRecipe
    settings {
      description
      name
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    scriptCode
    id
    createdAt
    updatedAt
    organizationRecipeId
    recipeUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRecipeMutationVariables,
  APITypes.CreateRecipeMutation
>;
export const updateRecipe = /* GraphQL */ `mutation UpdateRecipe(
  $input: UpdateRecipeInput!
  $condition: ModelRecipeConditionInput
) {
  updateRecipe(input: $input, condition: $condition) {
    editors
    readers
    admins
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    scriptRecipe
    settings {
      description
      name
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    scriptCode
    id
    createdAt
    updatedAt
    organizationRecipeId
    recipeUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRecipeMutationVariables,
  APITypes.UpdateRecipeMutation
>;
export const createVailRelease = /* GraphQL */ `mutation CreateVailRelease(
  $input: CreateVailReleaseInput!
  $condition: ModelVailReleaseConditionInput
) {
  createVailRelease(input: $input, condition: $condition) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    version
    name
    createdBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    releaseNotes
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    bundle {
      name
      version
      type
      extra
      __typename
    }
    history {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    organizationReleasesId
    vailReleaseCreatedById
    vailReleaseUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVailReleaseMutationVariables,
  APITypes.CreateVailReleaseMutation
>;
export const updateVailRelease = /* GraphQL */ `mutation UpdateVailRelease(
  $input: UpdateVailReleaseInput!
  $condition: ModelVailReleaseConditionInput
) {
  updateVailRelease(input: $input, condition: $condition) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    version
    name
    createdBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    releaseNotes
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    bundle {
      name
      version
      type
      extra
      __typename
    }
    history {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    organizationReleasesId
    vailReleaseCreatedById
    vailReleaseUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVailReleaseMutationVariables,
  APITypes.UpdateVailReleaseMutation
>;
export const createPromptSet = /* GraphQL */ `mutation CreatePromptSet(
  $input: CreatePromptSetInput!
  $condition: ModelPromptSetConditionInput
) {
  createPromptSet(input: $input, condition: $condition) {
    editors
    readers
    admins
    chat {
      role
      response
      __typename
    }
    maxTokens
    name
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    prompt
    temperature
    type
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    id
    createdAt
    updatedAt
    organizationPromptSetsId
    promptSetUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePromptSetMutationVariables,
  APITypes.CreatePromptSetMutation
>;
export const updatePromptSet = /* GraphQL */ `mutation UpdatePromptSet(
  $input: UpdatePromptSetInput!
  $condition: ModelPromptSetConditionInput
) {
  updatePromptSet(input: $input, condition: $condition) {
    editors
    readers
    admins
    chat {
      role
      response
      __typename
    }
    maxTokens
    name
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    prompt
    temperature
    type
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    id
    createdAt
    updatedAt
    organizationPromptSetsId
    promptSetUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePromptSetMutationVariables,
  APITypes.UpdatePromptSetMutation
>;
export const createNamespace = /* GraphQL */ `mutation CreateNamespace(
  $input: CreateNamespaceInput!
  $condition: ModelNamespaceConditionInput
) {
  createNamespace(input: $input, condition: $condition) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    name
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    documents {
      nextToken
      __typename
    }
    indexName
    status
    displayStatus
    id
    createdAt
    updatedAt
    organizationNamespacesId
    namespaceUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNamespaceMutationVariables,
  APITypes.CreateNamespaceMutation
>;
export const updateNamespace = /* GraphQL */ `mutation UpdateNamespace(
  $input: UpdateNamespaceInput!
  $condition: ModelNamespaceConditionInput
) {
  updateNamespace(input: $input, condition: $condition) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    name
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    documents {
      nextToken
      __typename
    }
    indexName
    status
    displayStatus
    id
    createdAt
    updatedAt
    organizationNamespacesId
    namespaceUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNamespaceMutationVariables,
  APITypes.UpdateNamespaceMutation
>;
export const createDocument = /* GraphQL */ `mutation CreateDocument(
  $input: CreateDocumentInput!
  $condition: ModelDocumentConditionInput
) {
  createDocument(input: $input, condition: $condition) {
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    namespace {
      editors
      readers
      admins
      name
      indexName
      status
      displayStatus
      id
      createdAt
      updatedAt
      organizationNamespacesId
      namespaceUpdatedById
      __typename
    }
    type
    source
    name
    active
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    status
    displayStatus
    visibility
    id
    createdAt
    updatedAt
    organizationDocumentsId
    namespaceDocumentsId
    documentUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentMutationVariables,
  APITypes.CreateDocumentMutation
>;
export const updateDocument = /* GraphQL */ `mutation UpdateDocument(
  $input: UpdateDocumentInput!
  $condition: ModelDocumentConditionInput
) {
  updateDocument(input: $input, condition: $condition) {
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    namespace {
      editors
      readers
      admins
      name
      indexName
      status
      displayStatus
      id
      createdAt
      updatedAt
      organizationNamespacesId
      namespaceUpdatedById
      __typename
    }
    type
    source
    name
    active
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    status
    displayStatus
    visibility
    id
    createdAt
    updatedAt
    organizationDocumentsId
    namespaceDocumentsId
    documentUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentMutationVariables,
  APITypes.UpdateDocumentMutation
>;
export const deleteDocument = /* GraphQL */ `mutation DeleteDocument(
  $input: DeleteDocumentInput!
  $condition: ModelDocumentConditionInput
) {
  deleteDocument(input: $input, condition: $condition) {
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    namespace {
      editors
      readers
      admins
      name
      indexName
      status
      displayStatus
      id
      createdAt
      updatedAt
      organizationNamespacesId
      namespaceUpdatedById
      __typename
    }
    type
    source
    name
    active
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    status
    displayStatus
    visibility
    id
    createdAt
    updatedAt
    organizationDocumentsId
    namespaceDocumentsId
    documentUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentMutationVariables,
  APITypes.DeleteDocumentMutation
>;
export const updateCustomComponent = /* GraphQL */ `mutation UpdateCustomComponent(
  $input: UpdateCustomComponentInput!
  $condition: ModelCustomComponentConditionInput
) {
  updateCustomComponent(input: $input, condition: $condition) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      id
      createdAt
      updatedAt
      organizationUpdatedById
      __typename
    }
    name
    description
    type
    settings
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    history {
      nextToken
      __typename
    }
    status
    displayStatus
    visibility
    id
    createdAt
    updatedAt
    organizationCustomComponentsId
    customComponentUpdatedById
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomComponentMutationVariables,
  APITypes.UpdateCustomComponentMutation
>;
