import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import removeNullishValues from '@/utils/removeNullishValues'

const getParams = (searchParams: URLSearchParams) => {
  const searchValues: Record<string, any> = {}
  for (let key of searchParams.keys()) {
    const value = searchParams.getAll(key)
    searchValues[key] = value.length === 1 ? value[0] : value
  }
  return searchValues
}

type Setter = (params: Record<string, any>) => void

export const useUrlParams = (): [Record<string, any>, Setter] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchValues = useMemo(() => getParams(searchParams), [searchParams])
  const setUrlParams: Setter = useCallback(
    (params) => {
      setSearchParams(removeNullishValues({ ...searchValues, ...params }))
    },
    [searchValues, setSearchParams]
  )

  return [searchValues, setUrlParams]
}

export default useUrlParams

// unit test of the helper functions in this file.
if (import.meta.vitest) {
  const { expect, describe, test } = import.meta.vitest
  describe('the getParams function', function () {
    test.each([
      [new URLSearchParams('a=1&b=2'), { a: '1', b: '2' }],
      [new URLSearchParams('a=1&a=2'), { a: ['1', '2'] }],
      [new URLSearchParams('a=1&a=2&b=2'), { a: ['1', '2'], b: '2' }],
    ])('getParams(%o) -> %o', (testValue, result) => {
      expect(getParams(testValue)).toEqual(result)
    })
  })
}
